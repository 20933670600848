<template>
    <section class="JengaImageGrid" :id="sliceId">
        <div class="JengaImageGrid__content">
            <h2
                v-if="slice.primary.section_title"
                class="JengaImageGrid__title"
            >
                <FillTextEffect
                    :trigger-container="'#' + sliceId"
                    bottom-position="center"
                    :text="slice.primary.section_title"
                />
            </h2>
            <PrismicRichText
                v-if="slice.primary.section_content.length"
                class="JengaImageGrid__body"
                :field="slice.primary.section_content"
            />
            <CustomButton
                v-if="slice.primary.button_text"
                :to="slice.primary.button_internal_link.url"
                :href="slice.primary.button_external_link.url"
                class="JengaImageGrid__btn"
            >
                {{ slice.primary.button_text }}
            </CustomButton>
        </div>
        <ul class="JengaImageGrid__media">
            <li
                v-for="(item, index) in slice.items"
                class="JengaImageGrid__item"
            >
                <div class="JengaImageGrid__item-inner">
                    <div class="JengaImageGrid__bg-expander">
                        <span
                            class="JengaImageGrid__number"
                            v-if="index < 3 && item.tag_text"
                        >
                            <span class="JengaImageGrid__number-text">
                                {{ item.tag_text }}
                            </span>
                        </span>
                    </div>
                    <span
                        class="JengaImageGrid__item-content"
                        v-if="item.text.length"
                    >
                        <PrismicRichText :field="item.text" />
                    </span>
                </div>
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

const props = defineProps(
    getSliceComponentProps<Content.JengaImageGridSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const sliceId = props.slice.id.replace('$', '-') + '-image-with-content'
</script>

<style lang="scss">
.JengaImageGrid {
    grid-column: 2 / span 22;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    column-gap: 9rem;
    align-items: center;
    padding-top: 8rem;

    @include large-up {
        flex-direction: row;
        padding-top: 11.25rem;
    }
}

.JengaImageGrid__content,
.JengaImageGrid__media {
    width: 100%;

    @include large-up {
        width: 50%;
    }
}

.JengaImageGrid__title {
    @include heading-2;

    margin-bottom: 1.25rem;
}

.JengaImageGrid__body {
    @include body-lg;

    font-weight: var(--font-weight-semibold);
}

.JengaImageGrid__btn {
    margin-top: 1.25rem;

    @include large-up {
        margin-top: 2rem;
    }
}

.JengaImageGrid__media {
    display: flex;
    flex-wrap: wrap;
}

.JengaImageGrid__item {
    position: relative;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 50%;

    &:hover {
        .JengaImageGrid__bg-expander {
            width: 105%;
            height: 105%;
            background-color: var(--black-30);
        }

        .JengaImageGrid__number:before {
            transform: rotate(90deg);
        }
    }

    &:nth-child(1) {
        .JengaImageGrid__item-inner {
            width: 100%;
        }
    }

    &:nth-child(2) {
        .JengaImageGrid__item-inner {
            width: 85%;
            bottom: 0;
            left: 10%;

            @include medium-up {
                width: 75%;
            }
        }
    }

    &:nth-child(3) {
        .JengaImageGrid__item-inner {
            width: 85%;
            right: 15%;
            top: 10%;

            @include medium-up {
                width: 75%;
            }
        }
    }

    &:nth-child(4) {
        .JengaImageGrid__item-inner {
            width: 70%;
            left: -5%;
            top: 10%;

            @include medium-up {
                width: 60%;
            }
        }
    }

    &:nth-child(5) {
        .JengaImageGrid__item-inner {
            width: 85%;
            left: 50%;
            top: 5%;
            transform: translateX(-50%);

            @include medium-up {
                width: 60%;
                top: -5%;
            }
        }
    }

    &:nth-child(6) {
        .JengaImageGrid__item-inner {
            width: 75%;
            left: 2%;
            top: -10%;

            @include medium-up {
                width: 60%;
                left: 50%;
                top: -5%;
                transform: translateX(-100%) translateY(-25%);
            }
        }
    }
}

.JengaImageGrid__item-inner {
    aspect-ratio: 1 / 1;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.JengaImageGrid__bg-expander {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--black-20);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
}

.JengaImageGrid__item-content {
    position: relative;
    margin: auto;
    padding: 1.25rem;
    font-size: 0.875rem;
    font-weight: var(--font-weight-bold);

    @include medium-up {
        font-size: 1rem;
    }
}

.JengaImageGrid__number {
    position: absolute;
    top: 5%;
    right: 6%;
    color: black;
    aspect-ratio: 1/1;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lime-green);

    @include medium-up {
        height: 2rem;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid var(--lime-green);
        transition: transform 0.3s ease;
    }
}

.JengaImageGrid__number-text {
    position: relative;
    font-family: var(--font-family-primary);
}
</style>
